/**
 * This file includes string helper functions.
 *
 * @copyright 19. August 2016 SpaceBoard
 */

String.prototype.capitalizeFirstLetter = function() {
    return this.charAt(0).toUpperCase() + this.slice(1);
};

String.prototype.snakeToHuman = function() {
    return this.replace('_', ' ').capitalizeFirstLetter();
};
