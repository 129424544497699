/* Form validation.
 *
 * Usage example:
 *
 * Twig:
 * ------------------------------------------------------------------------------------------
 * <div class="input-field">
 *  {{ form_widget(
 *  form.address.postalCode,
 *  {
 *  'id': 'field-1-id',
 *  'attr': {
 *  'data-message-required': "requiredMessageId"|trans({}, "Section")|desc("Message description"),
 *  'data-message-string': "stringRuleMessage"|trans({}, "Section")|desc("Field should contain {minLength} - {maxLength} symbols")
 *  }
 *  }) }}
 *  <label for="giPostalCode">{{ "postal_code"|trans({}, "GeneralInformation")|desc("Postal code") }}</label>
 *  </div>
 * ------------------------------------------------------------------------------------------
 *
 * JavaScript
 * ------------------------------------------------------------------------------------------
 * var form = new Form("#form-id");
 *
 * // check value to be a string 4-8 symbols.
 * // in the error messages specified as HTML elememt attributes, {optionName} is replaced with options.optionName
 * new Form.Field.string("#field-1-id", {minLength: 4, maxLength: 8}).addTo(form);
 *
 * var isValid = form.validate();
 * ------------------------------------------------------------------------------------------
 *
 * Error messages can be displayed in an arbitrary HTML element which you specify with
 *
 * Field.setMessageContainer("#messageContainerId")
 *
 * By default, however, all the error messages for a form are accumulated and displayed using showError() function,
 * in a floating red line on top of the page.
 *
 * @param {string|jQuery|HTMLFormElement} element
 * @constructor
 */

var Form = Class.extend({
    element: null,
    fields: [],
    messages: [],
    init: function(element, submitElement) {
        this.element = $(element);
        this.fields = [];
        this.messages = [];

        var form = this;
        var inputs = this.element.find('input');
        inputs.keydown(function(e) {
            if (e.which == 13)
                form.submit();
        });

        if (submitElement) {
            submitElement = $(submitElement);
            submitElement.click(function () {
                if (form.submit()) {
                    submitElement.unbind('click');
                }
            });
        }
    },
    /**
     * @param {Form.BaseField} field
     * @returns {Form}
     */
    add: function(field) {
        field.setForm(this);
        this.fields.push(field);
        return this;
    },
    /**
     * @returns {boolean}
     */
    validate: function() {
        this.messages = [];
        var valid = true;
        /**
         * @param {Form.Field} field
         */
        this.fields.map(function(field) {
            valid = field.check() && valid;
        });

        if (!valid && this.messages.length) {
            this.showMessages();
        }

        return valid;
    },
    /**
     * Submits the form, if it is valid.
     * @return boolean Is form valid, i.e. was it actually submitted?
     */
    submit: function() {
        return this.validate() && this.element.submit();
    },
    /**
     * Adds
     * @param message
     */
    addMessage: function(message) {
        if (message && message.length) {
            this.messages.push(message);
        }
    },
    /**
     * Shows all messages.
     */
    showMessages: function() {
        showError(this.messages.join(".\n"));
    }
});

Form.BaseField = Class.extend({
    element: null,
    options: {},
    rules: [],
    required: false,
    form: null,
    value: null,
    message: null,
    messageContainer: null,
    isEmpty: true,
    trim: true,
    init: function(element, options) {
        var outerThis = this;
        element = $(element);
        element.blur(function() {
            outerThis.check();
        });
        this.setMessageContainer(element.siblings('.input-error'));
        this.element = element;
        this.options = options || {};
    },
    /**
     * Check field
     * @returns {boolean}
     */
    check: function() {
        this.isEmpty = true;
        this.value = this.getElementValue();
        var ui = this.getUIElements();
        ui.removeClass("valid invalid");
        this.hideMessage();
        if (this.isValid()) {
            if (!this.isEmpty) {
                ui.addClass("valid");
            }
            return true;
        } else {
            ui.addClass("invalid");
            this.showMessage();
            return false;
        }
    },
    isValid: function() {
        throw "isValid() must be overriden!";
    },
    getElementValue: function() {
        throw "getElementValue() must be overriden!";
    },
    getUIElements: function() {
        return this.element;
    },
    /**
     * @param {Form} form
     * @returns {Form.Field}
     */
    addTo: function(form) {
        form.add(this);
        return this;
    },
    /**
     * @param {Form} form
     * @returns {Form.Field}
     */
    setForm: function(form) {
        this.form = form;
    },
    /**
     * Apply trim() to element values before using them. Default behavior.
     * @returns {Form.Field}
     */
    setTrim: function() {
        this.trim = true;
        return this;
    },
    /**
     * Do NOT apply trim() to element values before using them. You have to call this explicitly.
     * @returns {Form.Field}
     */
    setNoTrim: function() {
        this.trim = false;
        return this;
    },
    /**
     * Set required.
     *
     * In case arg is a function, this function will be called,
     * in order to determine whether the field is required.
     *
     * In case arg is omitted, it is treated as boolean TRUE. Otherwise it is converted to boolean.
     *
     * @param {bool|function(value, field)} arg
     * @returns {Form.Field}
     */
    setRequired: function(arg) {
        switch (typeof arg) {
            case "undefined":
                this.required = true;
                break;
            case "function":
                this.required = arg;
                break;
            default:
                this.required = arg ? true : false;
                break;
        }
        return this;
    },
    /**
     * Get current value, probably converted to int/float/whatever
     * @returns {*|string|Number}
     */
    getValue: function() {
        return this.value;
    },
    /**
     * Add additional validation rule.
     * @param {function(value, field)} rule
     * @returns {Form.Field}
     */
    addRule: function(rule) {
        this.rules.push(rule);
        return this;
    },
    /**
     * In case the field is required to fill, check for the value being not empty.
     * @returns {boolean}
     */
    checkRequired: function() {
        if ("function" == (typeof this.required)) {
            if (this.required.call(window, this.value, this)) {
                return this.doCheckRequired();
            } else {
                return true;
            }
        } else if (this.required) {
            return this.doCheckRequired();
        } else {
            return true;
        }
    },
    doCheckRequired: function() {
        this.isEmpty = (!this.value) || (!this.value.length);
        if (this.isEmpty) {
            this.setMessage(this.resolveMessage("required"));
            return false;
        } else {
            return true;
        }
    },
    /**
     * Resolve validation error message from the element's attributes.
     *
     * @param type
     * @returns {string}
     */
    resolveMessage: function(type) {
        return this.element.data("message-" + type)
            || this.element.data("message")
            || "Invalid " + (this.element.attr("title") || this.element.attr("name") || "field");
    },
    /**
     * Set error message. {optionName} is replaced with corresponding option's value.
     * @param {string} str
     * @returns {Form.Field}
     */
    setMessage: function(str) {
        for (var i in this.options) {
            if (this.options.hasOwnProperty(i)) {
                str = str.replace("{"+i+"}", this.options[i]);
            }
        }
        this.message = str;
        return this;
    },
    /**
     * Set HTML element to display message in
     * @param {selector|jQuery} element
     * @returns {Form.Field}
     */
    setMessageContainer: function(element) {
        if ($(element).length) {
            this.messageContainer = $(element);
        }
        this.hideMessage();
        return this;
    },
    hideMessage: function() {
        if (this.messageContainer) {
            this.messageContainer.hide();
        }
    },
    showMessage: function() {
        if (this.messageContainer) {
            this.messageContainer.text(this.message).show();
        } else {
            this.form.addMessage(this.message);
        }
    },
    /**
     * Check additional rules.
     * @returns {boolean}
     */
    checkRules: function() {
        for (var i = 0; i < this.rules.length; i++) {
            if (!this.rules[i].call(window, this.value, this)) {
                this.setMessage(this.resolveMessage("rules"));
                return false;
            }
        }
        return true;
    }
});

/**
 * @param {string|jQuery} element
 * @param {Form.Field.TYPE_XXX} type
 * @param {object} options
 * @constructor
 */
Form.Field = Form.BaseField.extend({
    type: null,
    init: function(element, type, options) {
        options = $.extend(
            {},
            Form.Field.DEFAULT_OPTIONS[type],
            options || {}
        );
        this._super(element, options);
        this.type = type;
    },
    isValid: function() {
        if (!this.required && this.isEmpty) return true;
        return this.checkRequired() && this.checkType() && this.checkRules();
    },
    /**
     * Gets the element value
     * @returns {string}
     */
    getElementValue: function() {
        var ret = this.element.val();
        if (this.trim) {
            ret = ret.trim();
            this.element.val(ret);
        }
        this.isEmpty = (ret.length == 0);
        return ret;
    },
    /**
     * Perform checks specific to field type.
     * @returns {boolean}
     */
    checkType: function() {
        var ret;
        switch (this.type) {
            case Form.Field.TYPE_INT:
                ret = this.checkInt();
                break;
            case Form.Field.TYPE_FLOAT:
                ret = this.checkFloat();
                break;
            case Form.Field.TYPE_STRING:
                ret = this.checkString();
                break;
            case Form.Field.TYPE_EMAIL:
                ret = this.checkEmail();
                break;
            case Form.Field.TYPE_PATTERN:
                ret = this.checkString() && this.checkPattern();
                break;
            case Form.Field.TYPE_REPEAT:
                ret = this.checkRepeat();
                break;
            case Form.Field.TYPE_CALLBACK:
                ret = this.checkCallback();
                break;
            case Form.Field.TYPE_ENUM:
                ret = this.checkEnum();
                break;
            default:
                throw "Unknown field type";
                break;
        }
        if (ret) {
            return true;
        } else {
            this.setMessage(this.resolveMessage(this.type));
            return false;
        }
    },
    checkInt: function() {
        var int = parseInt(this.value);
        if (isNaN(int)) {
            return false;
        } else {
            this.isEmpty = false;
            this.value = int;
            if (this.options.min && (this.value < this.options.min)) return false;
            if (this.options.max && (this.value > this.options.max)) return false;
            return true;
        }
    },
    checkString: function() {
        if ("string" != (typeof this.value)) return false;
        this.isEmpty = (this.value.length == 0);
        if (this.options.minLength && (this.value.length < this.options.minLength)) return false;
        if (this.options.maxLength && (this.value.length > this.options.maxLength)) return false;
        return true;
    },
    checkEmail: function() {
        if ("string" != (typeof this.value)) return false;
        return validateEmail(this.value);
    },
    checkPattern: function() {
        return this.value.match(this.options.regexp);
    },
    checkRepeat: function() {
        return this.value == this.options.repeatedField.value;
    },
    checkCallback: function() {
        this.isEmpty = (this.value.length == 0);
        return this.options.callback.call(window, this.value, this);
    },
    checkFloat: function() {
        var float = parseFloat(this.getElementValue().replace(",", "."));
        if (isNaN(float)) {
            return false;
        } else {
            this.value = float;
            this.isEmpty = false;
            if (this.options.min && (this.value < this.options.min)) return false;
            if (this.options.max && (this.value > this.options.max)) return false;
            return true;
        }
    },
    checkEnum: function() {
        this.isEmpty = (this.value.length == 0);
        return -1 != this.options.values.indexOf(this.value);
    }
});

Form.Field.TYPE_INT = "int";
Form.Field.TYPE_FLOAT = "float";
Form.Field.TYPE_STRING = "string";
Form.Field.TYPE_EMAIL = "email";
Form.Field.TYPE_PATTERN = "pattern";
Form.Field.TYPE_REPEAT = "repeat";
Form.Field.TYPE_CALLBACK = "callback";
Form.Field.TYPE_ENUM = "enum";

Form.Field.DEFAULT_OPTIONS = {
    int: {
        min: null,
        max: null
    },
    float: {
        min: null,
        max: null
    },
    string: {
        minLength: null,
        maxLength: null
    },
    email: {
        minLength: null,
        maxLength: null
    },
    pattern: {
        minLength: null,
        maxLength: null,
        regexp: null
    },
    repeat: {
        repeatedField: null
    },
    callback: {
        callback: function() {
            throw "Callback not specified!";
        }
    },
    enum: {
        values: []
    }
};

Form.Field.int = function(element, options) {
    return new Form.Field(element, Form.Field.TYPE_INT, options);
};

Form.Field.float = function(element, options) {
    return new Form.Field(element, Form.Field.TYPE_FLOAT, options);
};

Form.Field.string = function(element, options) {
    return new Form.Field(element, Form.Field.TYPE_STRING, options);
};

Form.Field.email = function(element, options) {
    return new Form.Field(element, Form.Field.TYPE_EMAIL, options);
};

Form.Field.pattern = function(element, regexp, options) {
    $.extend(options, {regexp: regexp});
    return new Form.Field(element, Form.Field.TYPE_PATTERN, options);
};

Form.Field.repeat = function(element, repeatedElement) {
    return new Form.Field(element, Form.Field.TYPE_REPEAT, {repeatedField: repeatedElement});
};

Form.Field.callback = function(element, callback) {
    return new Form.Field(element, Form.Field.TYPE_CALLBACK, {callback: callback});
};

Form.Field.enum = function(element, values) {
    return new Form.Field(element, Form.Field.TYPE_ENUM, {values: values});
};
/**
 * Form element accumulating 3 dropdown boxes: year, month, day.
 */
Form.Datepicker = Form.BaseField.extend({
    /**
     * Element argument refers to the wrapping DIV
     * @param element
     */
    init: function(element) {
        this._super(element);
    },
    /**
     * When dropdowns are not in focus, <input>s are displayed,
     * and valid/invalid styles should apply to these <input>s
     * @returns {jQuery}
     */
    getUIElements: function() {
        return this.element.find("input.select-dropdown");
    },
    /**
     * @returns {string} Y-m-d
     */
    getElementValue: function() {
        var ymd = [];
        try {
            this.element.find("select").each(function (i, el) {
                if (el.value) {
                    ymd[i] = el.value;
                } else {
                    throw "No value selected (" + el.name + ")";
                }
            });
            return ymd.join("-");
        } catch (e) {
            return "";
        }
    },
    isValid: function() {
        return this.checkRequired() && !this.isEmpty && this.checkRules();
    }
});
