/**
 * Contains helper functions to handle the tooltip framework.
 *
 * @copyright 30. December 2016 SpaceBoard
 */

/**
 * Finds all children of the given element with the tooltipped class, i.e. the elements containing a tooltip
 * and initializes the tooltip on them.
 *
 * @param element The parent element to search in
 */
function initializeTooltips(element) {
    if (element == null)
        element = $(document);
    else if (!(element instanceof jQuery))
        element = $(element);

    element.find('.tooltipped').each(function() {
        $(this).tooltip('remove');
        $(this).tooltip({delay: 50});
    });
}

/**
 * Finds all children of the given element containing tooltips and removes them.
 *
 * @param element The parent element to search in
 */
function removeTooltips(element) {
    if (element == null)
        element = $(document);
    else if (!(element instanceof jQuery))
        element = $(element);

    element.find('.tooltipped').each(function() {
        $(this).tooltip('remove');
    });
}
