/**
 * Auxiliary functions used everywhere.
 *
 * @copyright 8. September 2016 SpaceBoard
 */

/**
 * Checks a jQuery object for the presence of an attribute with the given name.
 *
 * @param name The name of the attribute to search for
 * @returns boolean true, if the object has an attribute with the given name
 *          false otherwise
 */
$.fn.hasAttr = function(name) {
    return this.attr(name) !== undefined;
};


/**
 * Calculates the width of a text
 */
$.fn.textWidth = function(text, font) {
    if (!$.fn.textWidth.fakeEl) $.fn.textWidth.fakeEl = $('<span>').hide().appendTo(document.body);
    $.fn.textWidth.fakeEl.text(text || this.val() || this.text());
    if (font)
        $.fn.textWidth.fakeEl.css('font', font);
    else {
        $.fn.textWidth.fakeEl.css('font-family', this.css('font-family'));
        $.fn.textWidth.fakeEl.css('font-size', this.css('font-size'));
        $.fn.textWidth.fakeEl.css('font-weight', this.css('font-weight'));
    }
    return $.fn.textWidth.fakeEl.width();
};


/**
 * Checks an HTML element for its content. If it's empty or only has whitespace elements inside it,
 * this will return true.
 *
 * @param element Could be an element, a jQuery element or just a jQuery selector string
 *        to identify the element to check
 * @returns boolean true, if the given element is empty
 */
function isEmpty(element) {
    return !$.trim($(element).html());
}


/**
 * Checks, if the given element is partly contained in the current viewport.
 *
 * @see http://stackoverflow.com/questions/123999/how-to-tell-if-a-dom-element-is-visible-in-the-current-viewport
 * @param element The element to check
 * @returns boolean Is element partly contained in the current viewport?
 */
function isElementInViewport(element) {
    if (typeof jQuery === "function" && element instanceof jQuery) {
        element = element[0];
    }

    if (element === undefined) return false;

    var rect = element.getBoundingClientRect();

    return (
        rect.bottom >= 100 &&
        rect.right >= 0 &&
        rect.top <= (window.innerHeight || document.documentElement.clientHeight) &&
        rect.left <= (window.innerWidth || document.documentElement.clientWidth) - 250
    );
}


/**
 * Validates a string against an email regex.
 *
 * @param emailString The string to check
 * @param validCallback A callback in case the email is valid
 * @param invalidCallback A callback in case the email is invalid
 * @returns boolean Is the input string a valid email?
 */
function validateEmail(emailString, validCallback, invalidCallback) {
    var emailRegEx = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/i;

    var valid = emailRegEx.test(emailString);

    if (validCallback && valid) {
        validCallback();
    }
    else if (invalidCallback && !valid) {
        invalidCallback();
    }

    return valid;
}
