/**
 * Shows messages in a dedicated bar at the top of the page.
 *
 * @copyright 19. September 2016 SpaceBoard
 */

/**
 * Displays a Board error message below the navigation bar.
 *
 * @param message The error message to display
 */
function showError(message) {
    var messageBar = $('#messageBar');
    messageBar.html(message);
    messageBar.removeClass('successBar');
    messageBar.addClass('errorBar');
    messageBar.slideDown('fast');
    setTimeout(function() {
        messageBar.slideUp('fast');
    }, 5000);
}

/**
 * Displays a Board success message below the navigation bar.
 *
 * @param message The success message to display
 */
function showSuccess(message) {
    var messageBar = $('#messageBar');
    messageBar.html(message);
    messageBar.removeClass('errorBar');
    messageBar.addClass('successBar');
    messageBar.slideDown('fast');
    setTimeout(function() {
        messageBar.slideUp('fast');
    }, 5000);
}
